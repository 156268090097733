<template>
  <div v-if="dictionary && Object.keys(dictionary).length > 0">
    <ValidationObserver v-slot="{ handleSubmit, errors }">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <router-link
                  tag="a"
                  to="/records/services"
                  class="button xbtn is-medium is-transparent"
                >
                  <icon name="x"></icon>
                </router-link>
                <h2 class="title" v-if="isNew">Dodaj</h2>
                <h2 class="title" v-else>Edytuj</h2>
              </div>
              <div class="buttons level-right">
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="
            errors['category'] && errors['category'].length > 0
              ? 'columns is-variable is-6 is-multiline v-field v-error'
              : 'columns is-variable is-6 is-multiline'
          "
        >
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Kategoria</label>
              <span
                v-if="errors['category'] && errors['category'].length > 0"
                class="help is-danger"
                >{{ errors["category"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="category">
                  <input
                    name="category"
                    v-model="service.category"
                    class="input is-medium"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['name'] && errors['name'].length > 0
              ? 'columns is-variable is-6 is-multiline v-field v-error'
              : 'columns is-variable is-6 is-multiline'
          "
        >
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Nazwa</label>
              <span
                v-if="errors['name'] && errors['name'].length > 0"
                class="help is-danger"
                >{{ errors["name"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="name">
                  <input
                    name="name"
                    v-model="service.name"
                    class="input is-medium"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['price'] && errors['price'].length > 0
              ? 'columns is-variable is-6 is-multiline v-field v-error'
              : 'columns is-variable is-6 is-multiline'
          "
        >
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Cena</label>
              <span
                v-if="errors['price'] && errors['price'].length > 0"
                class="help is-danger"
                >{{ errors["price"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="price">
                  <input
                    type="number"
                    name="price"
                    v-model="service.price"
                    class="input is-medium"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['minutes'] && errors['minutes'].length > 0
              ? 'columns is-variable is-6 is-multiline v-field v-error'
              : 'columns is-variable is-6 is-multiline'
          "
        >
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Czas w minutach</label>
              <span
                v-if="errors['minutes'] && errors['minutes'].length > 0"
                class="help is-danger"
                >{{ errors["minutes"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="minutes">
                  <input
                    type="number"
                    name="minutes"
                    v-model="service.minutes"
                    class="input is-medium"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ServicesForm",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FixedHeader,
    ValidationObserver,
    ValidationProvider,
  },
  data: function() {
    return {
      service: {},
    };
  },
  computed: {
    ...mapGetters({
      selectADevice: "orders/selectADevice",
      dictionary: 'dictionary',
    }),
    isNew() {
      return this.id === 0 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      getService: "records/getService",
      postService: "records/postService",
      disabledSidebar: "setDisabledSidebar",
    }),
    onSubmit() {
      this.postService({
        service: this.service,
        id: this.$route.params.id,
      })
        .then((resp) => {
          this.$router.push({
            name: "ServicesDetails",
            params: { id: resp.data.id },
          });
        })
        .catch((error) => {
          this.service = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },
  mounted() {
    this.disabledSidebar(true);
    if (!this.isNew) {
      this.getService({ id: this.$route.params.id })
        .then((resp) => (this.service = resp))
        .catch((error) => {
          this.service = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    }
  },
  destroyed() {
    if (!this.selectACustomer) {
      this.disabledSidebar(false);
    }
  },
};
</script>
